export function captureEvent({ category, action, label, value }) {
  dataLayer.push({
    // eslint-disable-line no-undef
    event: 'ga-event-push',
    'ga-event-category': category,
    'ga-event-action': action,
    'ga-event-label': label,
    'ga-event-value': value || '1'
  });
}
