import utils from './utils';

export default {
  searchPath(query) {
    return utils.buildURL('/search/topics', {
      q: query
    });
  },

  keywordSearchPath(query, sort = null) {
    const path = `/search/topics/q:${encodeURIComponent(query)}`;
    const params = {};
    params.sort = sort;
    return utils.buildURL(path, params);
  }
};
