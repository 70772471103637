export default {
  search_app_active: state => state.search_app_active,
  search_box_active: state => state.search_box_active,
  search_candidate_active: state => state.search_candidate_active,
  search_suggestion_active: state => state.search_suggestion_active,
  current_keyword: state => state.current_keyword,
  histories: state => state.histories,
  candidate_length: state => {
    if (state.search_candidate_active) {
      return state.criteria.criteria.length + state.histories.length;
    } else if (state.search_suggestion_active) {
      return state.keyword_suggestions.length;
    } else {
      return 0;
    }
  },
  focus_candidate_index: state => {
    return state.focus_candidate_index;
  },
  focus_criteria_index: state => {
    if (state.criteria.criteria.length === 0) {
      return null;
    }
    return state.focus_candidate_index;
  },
  focus_history_index: state => {
    if (state.focus_candidate_index === null) {
      return null;
    }
    if (state.histories.length === 0) {
      return null;
    }
    return state.focus_candidate_index - state.criteria.criteria.length;
  },
  focus_suggestion_index: state => {
    if (
      !state.search_suggestion_active &&
      state.keyword_suggestions.length === 0
    ) {
      return null;
    }
    return state.focus_candidate_index;
  },
  keyword_suggestions: state => state.keyword_suggestions
};
