export default {
  goToURL(path, params) {
    window.location.href = this.buildURL(path, params);
  },

  buildURL(path, params) {
    if (Array.isArray(params)) {
      return this.buildURLFromArray(path, params);
    } else {
      return this.buildURLFromObject(path, params);
    }
  },

  buildURLFromArray(path, paramPairs) {
    if (!paramPairs || paramPairs.length === 0) {
      return path;
    }

    const queryString = paramPairs
      .filter(pair => pair[1] !== null)
      .map(pair => pair.map(v => encodeURIComponent(v)).join('='))
      .join('&');
    return queryString ? `${path}?${queryString}` : path;
  },

  buildURLFromObject(path, params) {
    if (!params || Object.keys(params).length === 0) {
      return path;
    }

    // railsのヘルパと仕様を合わせてURLを正規化するため、パラメータを辞書順でソートする
    let keys = Object.keys(params);
    keys.sort();
    const pairArray = keys.map(key => [key, params[key]]);
    return this.buildURLFromArray(path, pairArray);
  }
};
