import { fetchKeywordSuggestions } from '../api';
import {
  INITIALIZE,
  SEARCH_APP_ACTIVE,
  SEARCH_BOX_ACTIVE,
  SEARCH_CANDIDATE_ACTIVE,
  SEARCH_SUGGESTION_ACTIVE,
  SET_CURRENT_KEYWORD,
  SET_KEYWORD_SUGGESTIONS,
  SET_HISTORIES,
  SET_FOCUS_CANDIDATE_INDEX
} from './mutation-types';
import { captureEvent } from '../../common/utils';
import utils, { UNFOCUSED } from '../utils';
import debounce from 'lodash/debounce';

// 履歴を保存する最大件数
const maxHistorySize = 10;

const debouncedFetchKeywordSuggestions = debounce((dispatch, keyword) => {
  dispatch('fetchKeywordSuggestions', keyword);
}, 500);

export default {
  mount: ({ commit, dispatch }, props) => {
    commit(INITIALIZE, props);

    if (props.loggedIn) {
      dispatch('initializeCriteria');
    }
  },

  showSearchScreen: ({ commit, dispatch, getters }) => {
    captureEvent({
      category: 'サイト内検索',
      action: 'HeaderPartsClick',
      label: 'search-form'
    });
    utils.addClassToBody('spfixed');
    commit(SEARCH_APP_ACTIVE, true);
    dispatch('showSuggestion', getters.current_keyword);
  },

  hideSearchScreen: ({ commit }) => {
    utils.removeClassFromBody('spfixed');
    commit(SET_CURRENT_KEYWORD, '');
    commit(SEARCH_APP_ACTIVE, false);
  },

  fetchKeywordSuggestions: async ({ commit }, keyword) => {
    const { data } = await fetchKeywordSuggestions(keyword);
    commit(SET_KEYWORD_SUGGESTIONS, data);
  },

  setCurrentKeyword: ({ commit }, keyword) => {
    commit(SET_CURRENT_KEYWORD, keyword);
  },

  updateKeywordAndSuggestions: ({ commit, dispatch }, keyword) => {
    dispatch('setCurrentKeyword', keyword);
    dispatch('showSuggestion', keyword);
  },

  showSuggestion: ({ commit, dispatch }, keyword) => {
    if (keyword.trim().length > 0) {
      commit(SEARCH_CANDIDATE_ACTIVE, false);
      commit(SEARCH_SUGGESTION_ACTIVE, true);
      debouncedFetchKeywordSuggestions(dispatch, keyword);
    } else {
      commit(SEARCH_CANDIDATE_ACTIVE, true);
      commit(SEARCH_SUGGESTION_ACTIVE, false);
    }
  },

  setFirstFocusCandidateIndex: ({ state, commit, getters }) => {
    if (getters.candidate_length > 0) {
      commit(SET_FOCUS_CANDIDATE_INDEX, 0);
    }
  },

  setLastFocusCandidateIndex: ({ state, commit, getters }) => {
    if (getters.candidate_length > 0) {
      commit(SET_FOCUS_CANDIDATE_INDEX, getters.candidate_length - 1);
    }
  },

  incrementFocusCandidateIndex: ({ state, commit, getters }) => {
    if (state.focus_candidate_index < getters.candidate_length - 1) {
      commit(SET_FOCUS_CANDIDATE_INDEX, state.focus_candidate_index + 1);
    } else {
      commit(SET_FOCUS_CANDIDATE_INDEX, UNFOCUSED);
    }
  },

  decrementFocusCandidateIndex: ({ state, commit }) => {
    if (state.focus_candidate_index) {
      commit(SET_FOCUS_CANDIDATE_INDEX, state.focus_candidate_index - 1);
    } else {
      commit(SET_FOCUS_CANDIDATE_INDEX, UNFOCUSED);
    }
  },

  resetFocusCandidateIndex: ({ commit }) => {
    commit(SET_FOCUS_CANDIDATE_INDEX, UNFOCUSED);
  },

  addToHistories: ({ state, commit }, keyword) => {
    const history = keyword.trim();
    if (history.length === 0) {
      return;
    }
    // 現在の履歴から追加するキーワードを取り除き、かつmaxHistorySizeに収まるようにする
    const histories = state.histories
      .filter(e => e !== history)
      .slice(0, maxHistorySize - 1);
    // 最初に挿入
    histories.unshift(history);
    commit(SET_HISTORIES, histories);
  },

  initializeHistories: ({ commit }) => {
    commit(SET_HISTORIES, []);
  },

  enableSearchBox({ commit }) {
    captureEvent({
      category: 'サイト内検索',
      action: 'HeaderPartsClick',
      label: 'search-form'
    });
    commit(SEARCH_BOX_ACTIVE, true);
  },

  disableSearchBox({ commit }) {
    commit(SEARCH_BOX_ACTIVE, false);
  }
};
