import * as mutations from './mutation-types';
import CriteriaAPI from '../../api/criteria';

const criteriaApi = new CriteriaAPI();

const SAVABLE_CRITERIA_LIMIT = 7;

const isSameCriteria = (a, b) => {
  const criteriaProps = ['keyword'];
  return criteriaProps.every(prop => (a[prop] || null) === (b[prop] || null));
};

export default {
  state: {
    criteria: [], // 保存された検索条件
    criteriaInitialized: false,
    currentCriterion: null,
    hasEmailRegistered: true,
    dialogVisible: false,
    registrationPath: null,
    searchCriteriaPath: null,
    criterionSaved: false,
    reachToLimitModalVisible: false
  },

  getters: {
    criteria: state => state.criteria,
    currentCriterion: state => state.currentCriterion,
    dialogVisible: state => state.dialogVisible,
    registrationPath: state => state.registrationPath,
    searchCriteriaPath: state => state.searchCriteriaPath,
    criterionSaved: state => state.criterionSaved,
    reachToLimitModalVisible: state => state.reachToLimitModalVisible,
    savableCriteriaLimit: _state => SAVABLE_CRITERIA_LIMIT,

    isCurrentCriteriaSavable(state, getters) {
      return state.criteriaInitialized && !getters.savedCriterion;
    },

    savedCriterion(state) {
      if (!state.currentCriterion) return null;

      for (let i = 0; i < state.criteria.length; i++) {
        const eachCriterion = state.criteria[i];
        if (isSameCriteria(eachCriterion, state.currentCriterion)) {
          return eachCriterion;
        }
      }
      return null;
    },

    // 通知を受け取ると設定されているのにメールアドレスが登録されていない？
    cannotReceiveCurrentNotification(state, getters) {
      return !!(
        !state.hasEmailRegistered &&
        getters.savedCriterion &&
        getters.savedCriterion.notify &&
        getters.criterionSaved
      );
    },

    isLimitReached(state) {
      return state.criteria.length >= SAVABLE_CRITERIA_LIMIT;
    }
  },

  mutations: {
    [mutations.INITIALIZE_CRITERIA](state, criteria) {
      state.criteria = criteria;
      state.criteriaInitialized = true;
    },

    [mutations.SET_DIALOG_VISIBLE](state, visible) {
      state.dialogVisible = visible;
    },

    [mutations.ADD_CRITERION](state, criteria) {
      state.criteria = [criteria].concat(state.criteria);
    },

    [mutations.CRITERION_SAVED](state, saved) {
      state.criterionSaved = saved;
    },

    [mutations.SET_REACH_TO_LIMIT_MODAL](state, visible) {
      state.reachToLimitModalVisible = visible;
    },

    [mutations.DELETE_CRITERION](state, deletesCriterionId) {
      state.criteria = state.criteria.filter(
        criterion => criterion.id !== deletesCriterionId
      );
    },

    [mutations.INITIALIZE_SAVE_CRITERIA](
      state,
      {
        currentCriterion,
        hasEmailRegistered,
        registrationPath,
        searchCriteriaPath
      }
    ) {
      state.currentCriterion = currentCriterion;
      state.hasEmailRegistered = hasEmailRegistered;
      state.registrationPath = registrationPath;
      state.searchCriteriaPath = searchCriteriaPath;
    }
  },

  actions: {
    initializeCriteria({ commit }) {
      criteriaApi.mines().then(({ data }) => {
        commit(mutations.INITIALIZE_CRITERIA, data.items);
      });
    },

    initializeSaveCriteria({ commit }, params) {
      commit(mutations.INITIALIZE_SAVE_CRITERIA, params);
    },

    showDialog({ commit, getters }) {
      if (getters.isLimitReached) {
        commit(mutations.SET_REACH_TO_LIMIT_MODAL, true);
      } else {
        commit(mutations.SET_DIALOG_VISIBLE, true);
      }
    },

    hideDialog({ commit }) {
      commit(mutations.SET_DIALOG_VISIBLE, false);
    },

    hideReachToLimitModal({ commit }) {
      commit(mutations.SET_REACH_TO_LIMIT_MODAL, false);
    },

    async saveCurrentCriterion({ commit, state }, notify) {
      const searchCriterion = await criteriaApi.add({
        criteria: { ...state.currentCriterion, notify: notify }
      });
      commit(mutations.ADD_CRITERION, searchCriterion);
      commit(mutations.CRITERION_SAVED, true);
      return searchCriterion;
    },

    async deleteCriterion({ commit }, { criterionId }) {
      await criteriaApi.delete(criterionId);
      commit(mutations.DELETE_CRITERION, criterionId);
    }
  }
};
