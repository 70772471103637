<template>
  <transition name="fade-search">
    <div
      class="header-sp__search-contents"
      v-if="search_app_active"
      data-testid="search-screen"
    >
      <search-form />
      <search-suggestions />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchForm from './SearchForm.vue';
import SearchSuggestions from './SearchSuggestions.vue';

export default {
  components: { SearchForm, SearchSuggestions },
  computed: {
    ...mapGetters(['search_app_active'])
  }
};
</script>
