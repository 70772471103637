// LocalStorageを使用できるかどうか
export function enabled() {
  // modenizrと同様の判別方法を使用
  // ref: https://bit.ly/2P5Ol1e
  try {
    const text = 'enabled';
    localStorage.setItem(text, text);
    localStorage.removeItem(text);
    return true;
  } catch (exception) {
    return false;
  }
}

// 検索履歴を取得または初期化
export function findOrInitializeHistories() {
  const historiesEncoded = localStorage.getItem('histories');
  if (historiesEncoded) {
    return JSON.parse(historiesEncoded);
  } else {
    return [];
  }
}

// 検索履歴を保存
export function saveHistories(histories) {
  localStorage.setItem('histories', JSON.stringify(histories));
}
