import axios from 'axios';

// meta[name=csrf-token]はRailsのCSRF対策token。それをデフォルトで設定したAxiosクライアントを生成する
export function createAxiosForRailsApi() {
  const tokenElement = document.querySelector('meta[name=csrf-token]');
  const client = axios.create({
    headers: {
      'X-CSRF-TOKEN': tokenElement ? tokenElement.content : null
    }
  });
  return client;
}
