import utils from '../../common/routings/utils';

export default {
  goToSearchURL(path, params) {
    utils.goToURL(path, params);
  },

  addClassToBody(className) {
    document.body.classList.add(className);
  },

  removeClassFromBody(className) {
    document.body.classList.remove(className);
  },

  replaceSlash(keyword) {
    return keyword.replace(/\//g, '／');
  }
};

export const UNFOCUSED = null;
