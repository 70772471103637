import { createAxiosForRailsApi } from '../../common/utils/http';

export default class CriteriaAPI {
  constructor() {
    this.client = createAxiosForRailsApi();
  }

  mines() {
    return this.client.get('/search/criterias');
  }

  add(params) {
    return this.client.post('/search/criterias', params).then(response => {
      if (!response.data.search_criteria) {
        throw response.data;
      }

      return Promise.resolve(response.data.search_criteria);
    });
  }

  delete(searchCriteriaId) {
    return this.client.delete(`/search/criterias/${searchCriteriaId}`);
  }
}
