import { createStore } from 'vuex';
import mutations from './mutations';
import { INITIALIZED, SET_HISTORIES } from './mutation-types';
import actions from './actions';
import getters from './getters';
import * as storage from '../localstorage';
import criteria from './criteria/index';

function buildSearchStore() {
  let store = createStore({
    state: {
      search_app_active: false,
      search_box_active: false,
      search_candidate_active: true,
      search_suggestion_active: false,
      current_keyword: '',
      keyword_suggestions: [], // 検索キーワードサジェスト(候補)、優先度順に並んでいる
      histories: [], // 検索履歴、新しいものから古いものの順に並んでいる
      focus_candidate_index: null, // focusされる検索候補のindex
      charged: false,
      is_new_account: false
    },
    mutations,
    actions,
    getters,
    modules: { criteria }
  });

  // localStorageが利用可能な場合
  if (storage.enabled()) {
    // mutationの実行後にLocalStorageに保存する
    store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case INITIALIZED:
          state.histories = storage.findOrInitializeHistories();
          break;
        case SET_HISTORIES:
          const histories = state.histories;
          storage.saveHistories(histories);
          break;
        default:
          break;
      }
    });
  }

  return store;
}

const defaultStore = buildSearchStore();
export default defaultStore;
export { buildSearchStore };
