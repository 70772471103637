// 初期化
export const INITIALIZE = 'INITIALIZE';
// 初期化時
export const INITIALIZED = 'INITIALIZED';
// Search App (SP用のUI)の表示、非表示
export const SEARCH_APP_ACTIVE = 'SEARCH_APP_ACTIVE';
// Search Box (PC用のUI)の表示、非表示
export const SEARCH_BOX_ACTIVE = 'SEARCH_BOX_ACTIVE';
// 検索履歴の表示、非表示
export const SEARCH_CANDIDATE_ACTIVE = 'SEARCH_CANDIDATE_ACTIVE';
// 検索キーワードサジェストの表示、非表示
export const SEARCH_SUGGESTION_ACTIVE = 'SEARCH_SUGGESTION_ACTIVE';
// 各UIに表示する検索キーワードを設定
export const SET_CURRENT_KEYWORD = 'SET_CURRENT_KEYWORD';
// 検索キーワードサジェストをセット
export const SET_KEYWORD_SUGGESTIONS = 'SET_KEYWORD_SUGGESTIONS';
// 検索履歴を変更
export const SET_HISTORIES = 'SET_HISTORIES';
// focusを当てる検索履歴のindex
export const SET_FOCUS_CANDIDATE_INDEX = 'SET_FOCUS_CANDIDATE_INDEX';
// 検索キーワード入力欄にフォーカス
export const FOCUS_SEARCH_INPUT = 'FOCUS_SEARCH_INPUT';
