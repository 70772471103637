<template>
  <div
    class="search-suggest search-suggest--sp"
    data-testid="search-suggestions"
  >
    <search-candidates v-if="search_candidate_active" />
    <keyword-suggestions v-if="search_suggestion_active" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchCandidates from './SearchCandidates.vue';
import KeywordSuggestions from './KeywordSuggestions.vue';

export default {
  computed: {
    ...mapGetters(['search_candidate_active', 'search_suggestion_active'])
  },
  components: { SearchCandidates, KeywordSuggestions }
};
</script>
