import * as types from './mutation-types';

export default {
  [types.INITIALIZE](state, active) {},
  [types.INITIALIZED](state, active) {},
  [types.SEARCH_APP_ACTIVE](state, active) {
    state.search_app_active = active;
  },
  [types.SEARCH_BOX_ACTIVE](state, active) {
    state.search_box_active = active;
  },
  [types.SEARCH_CANDIDATE_ACTIVE](state, active) {
    state.search_candidate_active = active;
  },
  [types.SEARCH_SUGGESTION_ACTIVE](state, active) {
    state.search_suggestion_active = active;
  },
  [types.SET_CURRENT_KEYWORD](state, keyword) {
    state.current_keyword = keyword;
  },
  [types.SET_KEYWORD_SUGGESTIONS](state, keywordSuggestions) {
    state.keyword_suggestions = keywordSuggestions;
  },
  [types.SET_HISTORIES](state, histories) {
    state.histories = histories;
  },
  [types.SET_FOCUS_CANDIDATE_INDEX](state, newIndex) {
    state.focus_candidate_index = newIndex;
  },
  [types.FOCUS_SEARCH_INPUT](state) {}
};
