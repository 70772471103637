<template>
  <div
    v-if="hasSuggestions"
    @keydown.up.prevent="handleUp"
    @keydown.down.prevent="handleDown"
    data-testid="keyword-suggestions"
  >
    <p class="search-suggest__title">検索候補</p>
    <ul class="search-suggest__list">
      <li
        class="search-suggest__item"
        :class="{ focus: focus_suggestion_index === index }"
        v-for="(keyword_suggestion, index) in keyword_suggestions"
        :key="keyword_suggestion"
      >
        <button
          class="search-history__button"
          type="button"
          :class="`js-focus${index}`"
          @click="goToSearchResults(keyword_suggestion, index)"
        >
          <i
            class="glyphs glyphs-search search-suggest__icon"
            aria-hidden="true"
          ></i>
          {{ keyword_suggestion }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_CURRENT_KEYWORD } from '../../store/mutation-types';
import { captureEvent } from '../../../common/utils';
import utils, { UNFOCUSED } from '../../utils';

export default {
  watch: {
    focus_suggestion_index: function(newIndex, _oldIndex) {
      // フォーカスが外れた場合はSearchInput.vueで処理
      if (newIndex !== UNFOCUSED) {
        const searchButtonEl = this.$el.querySelector(
          `.js-focus${this.focus_suggestion_index}`
        );
        if (searchButtonEl) searchButtonEl.focus();
      }
    }
  },

  computed: {
    ...mapGetters(['keyword_suggestions', 'focus_suggestion_index']),

    hasSuggestions() {
      return this.keyword_suggestions.length > 0;
    }
  },

  methods: {
    ...mapActions([
      'decrementFocusCandidateIndex',
      'incrementFocusCandidateIndex'
    ]),

    goToSearchResults(keyword, index) {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'suggest-search',
        value: index + 1
      });
      this.$store.commit(SET_CURRENT_KEYWORD, keyword);
      utils.goToSearchURL('/search/topics', { q: keyword });
    },

    handleUp() {
      this.decrementFocusCandidateIndex();
    },

    handleDown() {
      this.incrementFocusCandidateIndex();
    }
  }
};
</script>
