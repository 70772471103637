// 保存済み検索条件の初期化
export const INITIALIZE_CRITERIA = 'INITIALIZE_CRITERIA';
// 保存済み検索条件の追加
export const ADD_CRITERION = 'ADD_CRITERION';
// 保存済み検索条件の削除
export const DELETE_CRITERION = 'DELETE_CRITERION';
// 検索条件保存機能の初期化
export const INITIALIZE_SAVE_CRITERIA = 'INITIALIZE_SAVE_CRITERIA';
// 検査条件保存ダイアログの表示を切り替える
export const SET_DIALOG_VISIBLE = 'SET_DIALOG_VISIBLE';
// 検索条件を保存したかどうか
export const CRITERION_SAVED = 'CRITERION_SAVED';
// 保存可能件数上限に達したモーダルの表示切り替え
export const SET_REACH_TO_LIMIT_MODAL = 'SET_REACH_TO_LIMIT_MODAL';
